import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { prettyDate } from '../common/utils';

const Page = styled.div`
  max-width: 700px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 95vh;
  padding: 0 5px;
  @media (max-width: 1000px) {
    margin: 10px auto;
  }
`;

const Title = styled.h1`
  text-align: center;
  margin: 0.2em auto;
`;

const Subtitle = styled.div`
text-transform: uppercase;
color: ${(props) => props.theme.color.primary};
font-family ${({ theme }) => theme.font.heading};
font-size: 16px;
`;

const Author = styled.h3`
  margin-top: 2px;
  text-align: center;
`;

const PatchTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <Page>
        <Title>{frontmatter.title}</Title>
        {frontmatter.author && <Author>{frontmatter.author}</Author>}
        <Subtitle>
          {`${prettyDate(frontmatter.date)} | ${frontmatter.location}`}
        </Subtitle>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query PatchBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        title
        who
        location
        author
      }
    }
  }
`;

export default PatchTemplate;
